var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(
        _vm.$store.state.contexto.BD &&
        _vm.$store.state.contexto.perfilgeral === 'Colaborador'
      )?_c('v-app-bar',{style:({
        'font-family': 'Roboto',
        color: _vm.$store.state.temas.backgroundCorFonte,
      }),attrs:{"app":"","color":_vm.$store.state.temas.background,"dark":"","flat":"","height":"80"}},[(_vm.$store.state.contexto.BD)?_c('v-row',{staticStyle:{"margin-top":"25px"}},[_c('v-col',{attrs:{"cols":"10"}},[(_vm.$store.state.contexto.perfilgeral === 'Aluno')?_c('div',[_c('h4',{domProps:{"innerHTML":_vm._s(
                _vm.$store.state.contexto.aluno.cga +
                ' ' +
                _vm.$store.state.contexto.aluno.a_nome
              )}})]):_vm._e(),(
              _vm.$store.state.contexto.perfilgeral !== 'Aluno' &&
              _vm.$store.state.contexto.perfilgeral !== 'Professor'
            )?_c('table',{style:({
              height: '86px',
              'font-size': '14px',
              'margin-top':
                _vm.$store.state.contexto.perfilgeral === 'Aluno'
                  ? '-20px'
                  : '-40px',
            })},[_c('tr',[_c('td',{style:({
                  'border-right':
                    _vm.$store.state.contexto.aluno && _vm.drawer === false
                      ? '1px solid #64b5f6'
                      : null,
                })},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.drawer),expression:"!drawer"}],staticClass:"mdi mdi-menu",staticStyle:{"height":"10px","margin-right":"17px","font-size":"27px","cursor":"pointer"},on:{"click":function($event){_vm.drawer = true}}})]),_c('td',{staticStyle:{}},[_c('span',{staticClass:"mdi mdi-home",style:({
                    height: '10px',
                    color: _vm.$store.state.temas.backgroundCorFonte,
                    'margin-left': _vm.drawer ? '-5px' : '10px',
                    'margin-right': '5px',
                    'font-size': '27px',
                    cursor: 'pointer',
                  }),on:{"click":function($event){return _vm.setHome()}}})]),(_vm.$store.state.contexto.aluno)?_c('td',{staticStyle:{"width":"50%","padding-left":"5px"}},[_c('div',{staticStyle:{"text-align":"top"}},[_c('small',[_vm._v("Último aluno consultado")])]),_c('span',{staticStyle:{"text-align":"left"}},[_c('h5',{style:({
                      'font-size': '15px',
                      'text-overflow': 'ellipsis',
                      'white-space': 'nowrap',
                      overflow: 'hidden',
                      width: _vm.isMobile ? '200px' : '100%',
                    }),domProps:{"innerHTML":_vm._s(
                      _vm.$store.state.contexto.aluno.cga +
                      ' ' +
                      _vm.$store.state.contexto.aluno.a_nome
                    )}}),_c('span',[_vm._v(_vm._s(_vm.$store.state.contexto.aluno.a_email))])])]):_vm._e(),(_vm.$store.state.contexto.aluno && !_vm.isMobile)?_c('td',{staticStyle:{"width":"25%","padding-left":"5px","border-left":"1px solid #64b5f6"}},[_c('small',[_vm._v("Curso/Turno/Curriculo")]),_c('br'),_c('span',{staticStyle:{"font-size":"14px","text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden"}},[_vm._v(_vm._s(_vm.$store.state.contexto.aluno.s_descricao))]),_c('br'),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.$store.state.contexto.aluno.tur_descricao)+" - "+_vm._s(_vm.$store.state.contexto.aluno.g_descricao))]),_c('br')]):_vm._e(),(_vm.$store.state.contexto.aluno && !_vm.isMobile)?_c('td',{staticStyle:{"width":"25%","padding-left":"5px","border-left":"1px solid #64b5f6"}},[_c('small',[_vm._v("Unidade/Polo")]),_c('br'),_c('span',{staticStyle:{"font-size":"13px","text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden"}},[_vm._v(_vm._s(_vm.$store.state.contexto.aluno.e_nomefantasia))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.$store.state.contexto.aluno.cam_descricao))])]):_vm._e()])]):_vm._e()]),_c('v-col',{staticStyle:{"height":"81px","margin-top":"-25px","margin-right":"-55px","border-left":"1px solid #64b5f6","padding-bottom":"-5px"},attrs:{"cols":"2"}},[(_vm.$store.state.contexto.BD)?_c('div',{staticStyle:{"float":"right","color":"white","text-align":"right"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-row',{attrs:{"dense":""}},[_c('v-space'),_c('Button',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.contexto.BD),expression:"$store.state.contexto.BD"}],staticClass:"p-button-text",style:({
                      'margin-top': '12px',
                      color: _vm.$store.state.temas.backgroundCorFonte,
                      float: 'right',
                      'padding-right': '0px',
                      'font-size': '14px',
                      'font-family': 'Roboto',
                    }),attrs:{"iconPos":"right","label":!_vm.isMobile ? 'Encerrar sessão' : 'SAIR',"icon":"fal fa-power-off"},on:{"click":_vm.logout}})],1)],1)],1)],1):_vm._e()])],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$store.state.contexto.BD),expression:"!$store.state.contexto.BD"}]},[_c('span',{staticStyle:{"margin-left":"65px","font-family":"'Roboto'","font-weight":"400"}},[_vm._v("Sistema")]),_c('h1',{staticStyle:{"margin-top":"-15px","font-family":"'Roboto'","font-weight":"450"}},[_vm._v(" "+_vm._s(this.$store.state.contexto.BD[0].e_pasta)+" ")])])],1):_vm._e(),_c('v-main',[(
          _vm.$store.state.contexto.BD &&
          this.$store.state.contexto.BD[0].usr_nome !== undefined
        )?_c('v-footer',{staticClass:"mp-0 mb-0",staticStyle:{"border":"1px solid #ccc","padding-right":"5px"},attrs:{"padless":"","absolute":"","width":"auto"}},[_c('v-flex',{attrs:{"pl-4":""}},[_c('div',{staticStyle:{"font-size":"13px","background":"#ccc","padding":"2px","padding-left":"5px","border-radius":"5px"}},[_vm._v(" "+_vm._s(_vm.$store.state.contexto.titulo)+" ")])]),_c('v-spacer'),_c('v-layout',{attrs:{"justify-end":""}},[_c('small',{staticStyle:{"font-weight":"500"}},[_c('table',{staticStyle:{"padding":"0px","margin":"0px"}},[_c('tr',{staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._l((_vm.$store.state.contexto.favoritos),function(fav,index0){return (
                    !_vm.isMobile &&
                    _vm.$store.state.contexto.usuario.perfil.perfil.perfil ===
                      'Colaborador'
                  )?_c('td',{key:index0,staticStyle:{"font-family":"'Roboto'","font-size":"12px","cursor":"pointer","float":"right"},attrs:{"hover":""},on:{"click":function($event){fav.funcao();
                    _vm.$emit('mark', fav.titulo);}}},[(index0 < 20)?_c('v-icon',{staticStyle:{"font-size":"16px","margin-top":"5px","width":"30px"},attrs:{"center":"","title":fav.titulo}},[_vm._v(" "+_vm._s(fav.icon !== null && fav.icon !== "" ? fav.icon : "mdi mdi-help-circle")+" ")]):_vm._e()],1):_vm._e()}),(
                    _vm.$store.state.contexto.usuario.perfil.perfil.perfil ===
                    'Colaborador'
                  )?_c('td',{staticStyle:{"border-right":"1px solid #666","padding":"3px"}},[_vm._v(" "+_vm._s(this.$store.state.contexto.FILIAL[0].e_nomefantasia)+" ")]):_vm._e()],2)])])])],1):_vm._e(),_c('v-fade-transition',{attrs:{"leave-absolute":""}},[(
            _vm.$store.state.contexto.BD &&
            _vm.$store.state.contexto.usuario.perfil.perfil.perfil === 'Colaborador'
          )?_c('v-navigation-drawer',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.$store.state.contexto.usuario.perfil.perfil.perfil !==
              'Professor' &&
            _vm.$store.state.contexto.perfilgeral !== 'Aluno' &&
            _vm.$store.state.contexto.perfilgeral !== ''
          ),expression:"\n            $store.state.contexto.usuario.perfil.perfil.perfil !==\n              'Professor' &&\n            $store.state.contexto.perfilgeral !== 'Aluno' &&\n            $store.state.contexto.perfilgeral !== ''\n          "}],staticStyle:{"overflow-x":"hidden","overflow-y":"hidden","font-family":"'Roboto'"},attrs:{"width":"280","height":"650","app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"ma-0 pa-0"},[(_vm.$store.state.contexto.BD)?_c('v-list-item',{staticStyle:{"border-right":"1px solid #ccc","height":"10px","font-family":"'Roboto'","overflow":"hide","background-color":"#eceff1"},attrs:{"flat":""}},[_c('v-list-item',[_c('v-list-item',[_c('v-list-item-content',{staticStyle:{"margin-left":"-20px"}},[_c('span',{staticClass:"mdi mdi-arrow-left-bold-outline",staticStyle:{"float":"right","color":"#666","font-size":"15px","cursor":"pointer"},attrs:{"title":"Fechar menu"},on:{"click":function($event){_vm.drawer = false}}})])],1)],1),_c('v-divider')],1):_vm._e()],1),_c('v-divider',{staticStyle:{"padding-bottom":"10px"}}),_c('v-card-text',{staticClass:"ma-0 pa-0",staticStyle:{"scrollbar-width":"2px","height":"580px","overflow-y":"hide"},attrs:{"dark":""}},[_c('v-list',{staticClass:"overflow-y-auto",attrs:{"dense":""}},[_c('v-list-group',{attrs:{"append-icon":"","no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('span',{staticClass:"header"},[_c('v-list-item-title',{staticStyle:{"font-size":"14px"}},[_vm._v("Favoritos")])],1)])]},proxy:true}],null,false,4047517970)},_vm._l((_vm.$store.state.contexto.favoritos),function(fav,index0){return _c('v-expansion-panels',{key:index0,attrs:{"accordion":"","flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{style:(fav.titulo === _vm.idatual
                            ? 'font-weight: bold !important'
                            : null),attrs:{"dense":"","hide-actions":"","link":""}},[_c('span',{staticClass:"header"},[_c('div',{staticStyle:{"cursor":"pointer","margin-left":"15px","font-size":"13px"},on:{"click":function($event){return fav.funcao()}}},[_vm._v(" "+_vm._s(fav.titulo)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.drawer && fav.titulo === _vm.idatual),expression:"drawer && fav.titulo === idatual"}],staticStyle:{"float":"right","color":"black","font-size":"15px","cursor":"pointer"},on:{"click":function($event){_vm.drawer = false}}})])])])],1)],1)}),1),_vm._l((_vm.$store.state.contexto.menu),function(category,index){return _c('div',{key:index},[(category)?_c('v-list-group',{attrs:{"append-icon":"","no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(category.text))])],1)]},proxy:true}],null,true)},[_vm._l((_vm.filterVideos(category.text)),function(video,videoIndex){return _c('v-expansion-panels',{key:videoIndex,attrs:{"accordion":"","flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-content',[_c('div',{staticStyle:{"margin-left":"50px"}},[_vm._v(_vm._s(video.text))])])],1),_c('v-expansion-panel',{directives:[{name:"show",rawName:"v-show",value:(!video.click),expression:"!video.click"}]},[_c('v-expansion-panel-header',[_c('span',{staticClass:"header"},[_c('div',{staticStyle:{"margin-left":"10px"}},[_vm._v(" "+_vm._s(video.text)+" ")])])]),_vm._l((_vm.filterVideos1(
                            category.text,
                            video.text
                          )),function(video1,videoIndex1){return _c('v-expansion-panel-content',{key:videoIndex,style:(video1.text === _vm.idatual
                              ? 'font-weight: bold !important'
                              : null)},[_c('div',{staticStyle:{"font-size":"14px","vertical-align":"middle","cursor":"pointer","margin-left":"25px","padding":"0px"},on:{"click":function($event){_vm.mark(video1.text);
                              video1.click();}}},[_vm._v(" "+_vm._s(video1.text)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.drawer && video1.text === _vm.idatual),expression:"drawer && video1.text === idatual"}],staticStyle:{"float":"right","color":"black","font-size":"15px","cursor":"pointer"},on:{"click":function($event){_vm.drawer = false}}})])])})],2)],1)}),_vm._l((_vm.filterVideos(category.text)),function(video,videoIndex){return _c('v-expansion-panels',{key:videoIndex,attrs:{"accordion":"","flat":""}},[_c('v-expansion-panel',{directives:[{name:"show",rawName:"v-show",value:(video.click),expression:"video.click"}]},[_c('v-expansion-panel-header',{style:(video.text === _vm.idatual
                              ? 'font-weight: bold !important'
                              : null),attrs:{"dense":"","hide-actions":""}},[_c('span',{staticClass:"header"},[_c('div',{staticStyle:{"cursor":"pointer","margin-left":"10px"},on:{"click":function($event){_vm.mark(video.text);
                                video.click();}}},[_vm._v(" "+_vm._s(video.text)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.drawer && video.text === _vm.idatual),expression:"drawer && video.text === idatual"}],staticStyle:{"float":"right","color":"black","font-size":"15px","cursor":"pointer"},on:{"click":function($event){_vm.drawer = false}}})])])])],1)],1)})],2):_vm._e()],1)})],2)],1)],1)],1):_vm._e()],1),_c('router-view')],1),_c('v-dialog',{staticStyle:{"border-radius":"0px"},attrs:{"width":"600"},model:{value:(_vm.editarperfilusuario),callback:function ($$v) {_vm.editarperfilusuario=$$v},expression:"editarperfilusuario"}},[(_vm.editarperfilusuario)?_c('userprofile',{on:{"sair":function($event){_vm.editarperfilusuario = false}}}):_vm._e()],1),(_vm.trocarbd)?_c('selbancodados',{attrs:{"funcao":_vm.trocar}}):_vm._e(),_c('input',{ref:"file",staticStyle:{"display":"none"},attrs:{"type":"file"},on:{"change":_vm.upload_foto_perfil}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }