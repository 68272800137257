const cadcrm_ficha     = () => import("@/views/modulos/crm/cadcrm_ficha.vue");
const cadcrm_empresa     = () => import("@/views/modulos/crm/cadcrm_empresa.vue");
const cadcrm_empresa_tipo    = () => import("@/views/modulos/crm/cadcrm_empresa_tipo.vue");
const cadequipe_vendas    = () => import("@/views/modulos/crm/cadequipe_vendas.vue");
const cadleads    = () => import("@/views/modulos/crm/cadleads.vue");
const crmbase    = () => import("@/views/modulos/crm/crmbase.vue");
const cadcomunicados    = () => import("@/views/modulos/crm/cadcomunicados.vue");
const enviarcomunicados    = () => import("@/views/modulos/crm/enviarcomunicados.vue");
const cadcrm_indicadores_contatos    = () => import("@/views/modulos/crm/cadcrm_indicadores_contatos.vue");
const cadcrm_meiodivulgacao    = () => import("@/views/modulos/crm/cadcrm_meiodivulgacao.vue");
const cadcrm_veiculointegracao    = () => import("@/views/modulos/crm/cadcrm_veiculointegracao.vue");
const cadcrmIndicadores    = () => import("@/views/modulos/crm/cadcrm-indicadores.vue");
const cadvendedores    = () => import("@/views/modulos/crm/cadvendedores.vue");
const cadcrm_produtos    = () => import("@/views/modulos/crm/cadcrm_produtos.vue");
const painelcrm = () => import("@/views/modulos/crm/painel-crm.vue")

export default [
  {
    path: "/painelcrm/:inc:mod:exc:codsis",
    name: "painelcrm",
    props: true,
    component: painelcrm,
  },
  {
    path: "/cadcrm_produtos/:inc:mod:exc:codsis",
    name: "cadcrm_produtos",
    props: true,
    component: cadcrm_produtos,
  },
  {
    path: "/cadvendedores/:inc:mod:exc:codsis",
    name: "cadvendedores",
    props: true,
    component: cadvendedores,
  },
  {
    path: "/cadcrm-indicadores/:inc:mod:exc:codsis",
    name: "cadcrm-indicadores",
    props: true,
    component: cadcrmIndicadores,
  },
  {
    path: "/cadcrm_veiculointegracao/:inc:mod:exc:codsis",
    name: "cadcrm_veiculointegracao",
    props: true,
    component: cadcrm_veiculointegracao,
  },
  {
    path: "/cadleads/:inc:mod:exc:codsis",
    name: "cadleads",
    props: true,
    component: cadleads,
  },
  {
    path: "/cadcrm_meiodivulgacao/:inc:mod:exc:codsis",
    name: "cadcrm_meiodivulgacao",
    props: true,
    component: cadcrm_meiodivulgacao,
  },
  {
    path: "/cadcrm_indicadores_contatos/:inc:mod:exc:codsis",
    name: "cadcrm_indicadores_contatos",
    props: true,
    component: cadcrm_indicadores_contatos,
  },
  {
    path: "/cadcomunicados/:inc:mod:exc:codsis",
    name: "cadcomunicados",
    props: true,
    component: cadcomunicados,
  },
  {
    path: "/enviarcomunicados/:inc:mod:exc:codsis",
    name: "enviarcomunicados",
    props: true,
    component: enviarcomunicados,
  },

  {
    path: "/crmbase/:inc:mod:exc:codsis",
    name: "crmbase",
    props: true,
    component: crmbase,
  },
  {
    path: "/cadcrm_ficha/:inc:mod:exc:codsis",
    name: "cadcrm_ficha",
    props: true,
    component: cadcrm_ficha,
  },
    {
    path: "/cadcrm_empresa/:inc:mod:exc:codsis",
    name: "cadcrm_empresa",
    props: true,
    component: cadcrm_empresa,
  },    
    {
    path: "/cadcrm_empresa_tipo/:inc:mod:exc:codsis",
    name: "cadcrm_empresa_tipo",
    props: true,
    component: cadcrm_empresa_tipo,
  },    

      {
    path: "/cadequipe_vendas/:inc:mod:exc:codsis",
    name: "cadequipe_vendas",
    props: true,
    component: cadequipe_vendas,
  },    

    
];
