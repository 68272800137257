<template>
  <v-app>
    <v-app-bar
      v-if="
        $store.state.contexto.BD &&
        $store.state.contexto.perfilgeral === 'Colaborador'
      "
      app
      :color="$store.state.temas.background"
      :style="{
        'font-family': 'Roboto',
        color: $store.state.temas.backgroundCorFonte,
      }"
      dark
      flat
      height="80"
    >
      <v-row v-if="$store.state.contexto.BD" style="margin-top: 25px">
        <v-col cols="10">
          <div v-if="$store.state.contexto.perfilgeral === 'Aluno'">
            <h4
              v-html="
                $store.state.contexto.aluno.cga +
                ' ' +
                $store.state.contexto.aluno.a_nome
              "
            ></h4>
          </div>
          <table
            v-if="
              $store.state.contexto.perfilgeral !== 'Aluno' &&
              $store.state.contexto.perfilgeral !== 'Professor'
            "
            :style="{
              height: '86px',
              'font-size': '14px',
              'margin-top':
                $store.state.contexto.perfilgeral === 'Aluno'
                  ? '-20px'
                  : '-40px',
            }"
          >
            <tr>
              <td
                :style="{
                  'border-right':
                    $store.state.contexto.aluno && drawer === false
                      ? '1px solid #64b5f6'
                      : null,
                }"
              >
                <span
                  v-show="!drawer"
                  style="
                    height: 10px;
                    margin-right: 17px;
                    font-size: 27px;
                    cursor: pointer;
                  "
                  class="mdi mdi-menu"
                  @click="drawer = true"
                ></span>
              </td>

              <td style="1px solid #eee">
                <span
                  :style="{
                    height: '10px',
                    color: $store.state.temas.backgroundCorFonte,
                    'margin-left': drawer ? '-5px' : '10px',
                    'margin-right': '5px',
                    'font-size': '27px',
                    cursor: 'pointer',
                  }"
                  class="mdi mdi-home"
                  @click="setHome()"
                ></span>
              </td>
              <td
                style="width: 50%; padding-left: 5px"
                v-if="$store.state.contexto.aluno"
              >
                <div style="text-align: top">
                  <small>Último aluno consultado</small>
                </div>
                <span style="text-align: left">
                  <h5
                    :style="{
                      'font-size': '15px',
                      'text-overflow': 'ellipsis',
                      'white-space': 'nowrap',
                      overflow: 'hidden',
                      width: isMobile ? '200px' : '100%',
                    }"
                    v-html="
                      $store.state.contexto.aluno.cga +
                      ' ' +
                      $store.state.contexto.aluno.a_nome
                    "
                  ></h5>
                  <span>{{ $store.state.contexto.aluno.a_email }}</span>
                </span>
              </td>

              <td
                style="
                  width: 25%;
                  padding-left: 5px;
                  border-left: 1px solid #64b5f6;
                "
                v-if="$store.state.contexto.aluno && !isMobile"
              >
                <small>Curso/Turno/Curriculo</small><br />
                <span
                  style="
                    font-size: 14px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  "
                  >{{ $store.state.contexto.aluno.s_descricao }}</span
                ><br />
                <span style="font-size: 12px"
                  >{{ $store.state.contexto.aluno.tur_descricao }} -
                  {{ $store.state.contexto.aluno.g_descricao }}</span
                ><br />
              </td>
              <td
                style="
                  width: 25%;
                  padding-left: 5px;
                  border-left: 1px solid #64b5f6;
                "
                v-if="$store.state.contexto.aluno && !isMobile"
              >
                <small>Unidade/Polo</small><br />
                <span
                  style="
                    font-size: 13px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  "
                  >{{ $store.state.contexto.aluno.e_nomefantasia }}</span
                ><br />
                <span>{{ $store.state.contexto.aluno.cam_descricao }}</span>
              </td>
            </tr>
          </table>
        </v-col>
        <v-col
          cols="2"
          style="
            height: 81px;
            margin-top: -25px;
            margin-right: -55px;
            border-left: 1px solid #64b5f6;
            padding-bottom: -5px;
          "
        >
          <div
            style="float: right; color: white; text-align: right"
            v-if="$store.state.contexto.BD"
          >
            <v-row dense>
              <v-col>
                <v-row dense>
                  <v-space></v-space>
                  <Button
                    v-show="$store.state.contexto.BD"
                    :style="{
                      'margin-top': '12px',
                      color: $store.state.temas.backgroundCorFonte,
                      float: 'right',
                      'padding-right': '0px',
                      'font-size': '14px',
                      'font-family': 'Roboto',
                    }"
                    @click="logout"
                    iconPos="right"
                    :label="!isMobile ? 'Encerrar sessão' : 'SAIR'"
                    icon="fal fa-power-off"
                    class="p-button-text"
                  ></Button>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <div v-show="!$store.state.contexto.BD">
        <span style="margin-left: 65px; font-family: 'Roboto'; font-weight: 400"
          >Sistema</span
        >
        <h1 style="margin-top: -15px; font-family: 'Roboto'; font-weight: 450">
          {{ this.$store.state.contexto.BD[0].e_pasta }}
        </h1>
      </div>
    </v-app-bar>
    <v-main>
      <v-footer
        class="mp-0 mb-0"
        padless
        absolute
        style="border: 1px solid #ccc; padding-right: 5px"
        width="auto"
        v-if="
          $store.state.contexto.BD &&
          this.$store.state.contexto.BD[0].usr_nome !== undefined
        "
      >
        <v-flex pl-4>
          <div
            style="
              font-size: 13px;
              background: #ccc;
              padding: 2px;
              padding-left: 5px;
              border-radius: 5px;
            "
          >
            {{ $store.state.contexto.titulo }}
          </div>
        </v-flex>

        <v-spacer></v-spacer>

        <v-layout justify-end>
          <small style="font-weight: 500">
            <table style="padding: 0px; margin: 0px">
              <tr
                style="
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                <td
                  v-if="
                    !isMobile &&
                    $store.state.contexto.usuario.perfil.perfil.perfil ===
                      'Colaborador'
                  "
                  v-for="(fav, index0) in $store.state.contexto.favoritos"
                  :key="index0"
                  hover
                  @click="
                    fav.funcao();
                    $emit('mark', fav.titulo);
                  "
                  style="
                    font-family: 'Roboto';
                    font-size: 12px;
                    cursor: pointer;
                    float: right;
                  "
                >
                  <v-icon
                    v-if="index0 < 20"
                    style="font-size: 16px; margin-top: 5px; width: 30px"
                    center
                    :title="fav.titulo"
                  >
                    {{
                      fav.icon !== null && fav.icon !== ""
                        ? fav.icon
                        : "mdi mdi-help-circle"
                    }}
                  </v-icon>
                </td>
                <!--
                <td style="border-right: 1px solid #666; padding: 3px">{{ this.$store.state.contexto.BD[0].bco_empresa }}</td>
-->
                <td
                  v-if="
                    $store.state.contexto.usuario.perfil.perfil.perfil ===
                    'Colaborador'
                  "
                  style="border-right: 1px solid #666; padding: 3px"
                >
                  {{ this.$store.state.contexto.FILIAL[0].e_nomefantasia }}
                </td>
                <!--
                <td>
                  <v-avatar
                    color="#ddd"
                    size="20"
                    v-if="
                      $store.state.contexto.usuario.perfil.perfil.perfil !==
                      'Aluno'
                    "
                  >
                    <v-btn icon fab flat>
                      <v-icon @click="editarperfilusuario = true"
                        >mdi-account-edit</v-icon
                      >
                    </v-btn>
                  </v-avatar>
                </td>
                -->
                <!--
                <td style="padding: 3px">
                  {{
                    this.$store.state.contexto.BD[0].usr_nome +
                    (this.$store.state.contexto.BD[0].gu_descricao !==
                      undefined &&
                    this.$store.state.contexto.BD[0].gu_descricao !== null
                      ? "(" +
                        this.$store.state.contexto.BD[0].gu_descricao +
                        ")"
                      : "")
                  }}
                </td>
                -->
                <!--
                <td
                  v-if="!isMobile"
                  style="
                    width: 100px;
                    text-align: center;
                    color: #fb8c00;
                    background-color: black;
                    border-left: 1px solid #666;
                    padding: 3px;
                    font-weight: 650;
                  "
                >
                  {{ this.$store.state.contexto.FILIAL[0].e_pasta }}
                </td>
                -->
              </tr>
            </table>
          </small>
        </v-layout>
      </v-footer>

      <v-fade-transition leave-absolute>
        <v-navigation-drawer
          width="280"
          height="650"
          v-model="drawer"
          app
          style="overflow-x: hidden; overflow-y: hidden; font-family: 'Roboto'"
          v-if="
            $store.state.contexto.BD &&
            $store.state.contexto.usuario.perfil.perfil.perfil === 'Colaborador'
          "
          v-show="
            $store.state.contexto.usuario.perfil.perfil.perfil !==
              'Professor' &&
            $store.state.contexto.perfilgeral !== 'Aluno' &&
            $store.state.contexto.perfilgeral !== ''
          "
        >
          <v-card elevation="0">
            <v-card-title class="ma-0 pa-0">
              <v-list-item
                v-if="$store.state.contexto.BD"
                style="
                  border-right: 1px solid #ccc;
                  height: 10px;
                  font-family: 'Roboto';
                  overflow: hide;
                  background-color: #eceff1;
                "
                flat
              >
                <v-list-item>
                  <!--
                  <v-list-item-avatar
                    color="transparent"
                    size="66"
                    style="margin-left: -20px"
                  >
                    <v-img
                      style="cursor: pointer"
                      size="60"
                      @click="carregar_foto"
                      v-if="$store.state.usr_foto_perfil !== null"
                      :src="
                        Config.baseApiUrlUpload +
                        '/' +
                        $store.state.contexto.BD[0].bco_pasta.trim() +
                        '/usuarios/perfil/' +
                        $store.state.contexto.BD[0].usr_id +
                        '/' +
                        $store.state.usr_foto_perfil
                      "
                    ></v-img>
                    <v-icon
                      v-if="this.$store.state.usr_foto_perfil === null"
                      title="Clique para carregar a foto do perfil"
                      @click="carregar_foto"
                      size="60"
                      >mdi-account-circle</v-icon
                    >
                  </v-list-item-avatar>
-->
                  <v-list-item>
                    <v-list-item-content style="margin-left: -20px">
                      <!--
                      <v-list-item-title style="color: #666 !important">
                        {{ $store.state.contexto.BD[0].usr_nome }}
                      </v-list-item-title>
                      <v-list-item-subtitle style="color: #666 !important">{{
                        $store.state.contexto.BD[0].gu_descricao
                      }}</v-list-item-subtitle>
-->
                      <span
                        style="
                          float: right;
                          color: #666;
                          font-size: 15px;
                          cursor: pointer;
                        "
                        class="mdi mdi-arrow-left-bold-outline"
                        title="Fechar menu"
                        @click="drawer = false"
                      ></span>
                      <!--
                      <Button
                        @click="editarperfilusuario = true"
                        icon="mdi mdi-account-edit"
                        label="Trocar senha"
                        class="p-button-sm"
                      ></Button>
                      -->
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item>
                <v-divider />
              </v-list-item>
            </v-card-title>
            <v-divider style="padding-bottom: 10px"></v-divider>
            <v-card-text
              class="ma-0 pa-0"
              dark
              style="scrollbar-width: 2px; height: 580px; overflow-y: hide"
            >
              <v-list dense class="overflow-y-auto">
                <v-list-group append-icon="" no-action>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <span class="header">
                        <v-list-item-title style="font-size: 14px"
                          >Favoritos</v-list-item-title
                        >
                      </span>
                    </v-list-item-content>
                  </template>

                  <v-expansion-panels
                    v-for="(fav, index0) in $store.state.contexto.favoritos"
                    :key="index0"
                    accordion
                    flat
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        dense
                        hide-actions
                        link
                        :style="
                          fav.titulo === idatual
                            ? 'font-weight: bold !important'
                            : null
                        "
                      >
                        <span class="header">
                          <div
                            @click="fav.funcao()"
                            style="
                              cursor: pointer;
                              margin-left: 15px;
                              font-size: 13px;
                            "
                          >
                            {{ fav.titulo }}
                            <span
                              v-show="drawer && fav.titulo === idatual"
                              style="
                                float: right;
                                color: black;
                                font-size: 15px;
                                cursor: pointer;
                              "
                              @click="drawer = false"
                            ></span>
                          </div>
                        </span>
                      </v-expansion-panel-header>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-list-group>

                <div
                  v-for="(category, index) in $store.state.contexto.menu"
                  :key="index"
                >
                  <v-list-group v-if="category" append-icon="" no-action>
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 14px">{{
                          category.text
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-expansion-panels
                      v-for="(video, videoIndex) in filterVideos(category.text)"
                      :key="videoIndex"
                      accordion
                      flat
                    >
                      <v-expansion-panel>
                        <v-expansion-panel-content>
                          <div style="margin-left: 50px">{{ video.text }}</div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel v-show="!video.click">
                        <v-expansion-panel-header>
                          <span class="header">
                            <div style="margin-left: 10px">
                              {{ video.text }}
                            </div>
                          </span>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content
                          :style="
                            video1.text === idatual
                              ? 'font-weight: bold !important'
                              : null
                          "
                          v-for="(video1, videoIndex1) in filterVideos1(
                            category.text,
                            video.text
                          )"
                          :key="videoIndex"
                        >
                          <div
                            @click="
                              mark(video1.text);
                              video1.click();
                            "
                            style="
                              font-size: 14px;
                              vertical-align: middle;
                              cursor: pointer;
                              margin-left: 25px;
                              padding: 0px;
                            "
                          >
                            {{ video1.text }}
                            <span
                              v-show="drawer && video1.text === idatual"
                              style="
                                float: right;
                                color: black;
                                font-size: 15px;
                                cursor: pointer;
                              "
                              @click="drawer = false"
                            ></span>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels
                      v-for="(video, videoIndex) in filterVideos(category.text)"
                      :key="videoIndex"
                      accordion
                      flat
                    >
                      <v-expansion-panel v-show="video.click">
                        <v-expansion-panel-header
                          dense
                          hide-actions
                          :style="
                            video.text === idatual
                              ? 'font-weight: bold !important'
                              : null
                          "
                        >
                          <span class="header">
                            <div
                              @click="
                                mark(video.text);
                                video.click();
                              "
                              style="cursor: pointer; margin-left: 10px"
                            >
                              {{ video.text }}

                              <span
                                v-show="drawer && video.text === idatual"
                                style="
                                  float: right;
                                  color: black;
                                  font-size: 15px;
                                  cursor: pointer;
                                "
                                @click="drawer = false"
                              ></span>
                            </div>
                          </span>
                        </v-expansion-panel-header>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-list-group>
                </div>
              </v-list>
            </v-card-text>
          </v-card>
        </v-navigation-drawer>
      </v-fade-transition>
      <router-view></router-view>
    </v-main>

    <v-dialog
      v-model="editarperfilusuario"
      width="600"
      style="border-radius: 0px"
    >
      <userprofile
        v-if="editarperfilusuario"
        @sair="editarperfilusuario = false"
      ></userprofile>
    </v-dialog>

    <selbancodados v-if="trocarbd" :funcao="trocar" />

    <input
      type="file"
      ref="file"
      style="display: none"
      @change="upload_foto_perfil"
    />
  </v-app>
</template>

<script>
import axios from "axios";
import http from "@/servicos/http.ts";
import Menu from "@/components/menu.vue";
import userprofile from "@/components/userprofile";
import selbancodados from "@/views/modulos/auth/selbancodados";
import menufav from "@/views/menufav";
import Config, { baseApiUrlUpload } from "@/config.ts";
import NotificationService from "./NotificationService";

export default {
  components: {
    userprofile,
    Menu,
    selbancodados,
    menufav,
  },
  data() {
    return {
      isMobile: false,
      Config,
      foto_usuario: "",
      idatual: null,
      editarperfilusuario: false,
      drawer: false,
      trocarbd: false,
      group: true,
      menu: "",
    };
  },
  mounted() {
    this.isMobile = window.innerWidth < 600;
    NotificationService.notify(
      "O que a humanidade tem mais do que eu?",
      "Humanidade tem que acabar"
    );
    this.$on("mark", this.mark);
  },
  created() {
    /// this.getEmpresas().then(() => {
    //   console.log("banco de dados", this.$store.state.params.DB);
    // });
  },

  methods: {
    async getEmpresas() {
      const resposta = await fetch("params.json");
      const json = await resposta.json();
      this.$store.state.params.DB = json.DB;
      return json;
    },
    carregar_foto() {
      this.$refs.file.click();
    },

    upload_foto_perfil(file) {
      const files = event.target.files[0];
      console.log(files);

      const formData = new FormData();
      const url =
        Config.baseApiUrlUpload +
        `/upload?destino=${this.$store.state.contexto.BD[0].bco_pasta.trim()}/usuarios/perfil&codigo=${
          this.$store.state.contexto.BD[0].usr_id
        }`;

      formData.append("file", files);

      http
        .send("", {
          proc: `[FX jaguar registro docentes] 'inc-foto-usuario','${files.name}',null,${this.$store.state.contexto.BD[0].usr_id}`,
        })
        .then(() => {
          this.$store.state.contexto.usuario.usr_foto_perfil = files.name;
          axios.post(url, formData).then(() => {
            this.$toast.success("uploaded " + files.name);
            this.carrega_foto_perfil(files.name);
          });
        });
    },

    carrega_foto_perfil(filename) {
      console.log("carregando foto perfil");
      console.log(this.$store.state);
      console.log(this.$store.state.contexto);

      this.$store.commit("setFotoPerfil", filename);
      /*
      this.foto_usuario = `${Config.baseApiUrlUpload}/${this.$store.state.contexto.BD[0].bco_pasta.trim()}/usuarios/perfil/${
        this.$store.state.contexto.BD[0].usr_id
      }/${this.$store.state.usr_foto_perfil}`;
      console.log("foto usuario");
      console.log(this.$store.state);
      this.foto_usuario === undefined ? "" : this.foto_usuario;
*/
    },

    setHome() {
      this.$router.push({ name: `dashboard` });
    },
    mark(item) {
      console.log("mark");
      this.idatual = item;
      this.drawer = false;
    },
    filterVideos(cat) {
      this.menu = this.$store.state.contexto.menu;
      this.menuitens = this.menu.filter((m) => m.text === cat)[0].menu;
      return this.menuitens;
    },

    filterVideos1(cat, cat1) {
      console.log("montando o nivel 2");
      this.menu = this.$store.state.contexto.menu;
      this.overlay = true;
      this.menuitens = this.menu.filter((m) => m.text === cat)[0].menu;
      this.menuitens = this.menuitens.filter((m) => m.text === cat1)[0].menu;
      this.overlay = false;
      return this.menuitens;
    },

    montasubgrupo(event) {
      this.menuitens = this.menu.filter((m) => m.text === event)[0].menu;
    },

    logout() {
      if (this.$store.state.contexto.usuariosConectados.lenght > 0) {
        var dados = { usr_nome: this.$store.state.contexto.BD[0].usr_nome };
        this.$store.state.contexto.ws_client.send(
          "/app/disconnect",
          JSON.stringify(dados),
          {}
        );
        this.$store.state.contexto.ws_client.disconnect();
        this.$store.state.contexto.titulo = null;
      }
      this.drawer = false;
      this.$router.push("/login");
      this.$store.commit("setMenu", null);
      this.$store.state.contexto.BD = null;
      this.$store.state.contexto.titulo = "";
    },
    mudarcontexto(item) {
      console.log("mudar contexto");
      this.$store.commit(
        "setBD",
        this.$store.state.contexto.BDLista.filter((b) => {
          return b.bco_nome === item;
        })
      );

      console.log("trocar bd");
      this.trocarbd = true;
    },
  },
};
</script>
<style lang="scss">
.negrito {
  font-weight: 600;
}
::-webkit-scrollbar {
  width: 8px;
  scroll-behavior: smooth;
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  border-radius: 15px;
  background-clip: padding-box;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.marcar {
  font-weight: 600;
}

.v-select {
  font-size: 14px;
}

.v-text-field {
  font-size: 14px;
}
.centralizar {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
body {
  font-family: Roboto, Arial, Helvetica, sans-serif;
}
.msgerro {
  color: red;
}

#app {
  font-family: "Roboto", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
